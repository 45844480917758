class Scan {
    constructor({
        design = "",
        brand = "",
        artist = "",
        typeKey="",
        currency_code = "usd",
        country_code = "us",
        canvas_height = 0,
        canvas_width = 0,
        diamondTypeList = [],
        no_of_color = 0,
        special_status = false,
        tags = [],
        image = [],
        variants = []
    }) {
        this.design = design;
        this.brand = brand;
        this.artist = artist;
        this.typeKey = typeKey;
        this.currency_code = currency_code;
        this.country_code = country_code;
        this.canvas_height = canvas_height;
        this.canvas_width = canvas_width;
        this.diamondTypeList = diamondTypeList;
        this.no_of_color = no_of_color;
        this.special_status = special_status;
        this.tags = tags;
        this.image = image;
        this.variants = variants.map(variant => new Variant(variant));
    }
}

class Variant {
    constructor({
        variant_id = "",
        variant_title = "",
        variant_key = "",
        price = "0.00",
        total_amount_diamonds = 0,
        abs = 0,
        crystal = 0,
        glow = 0
    }) {
        this.variant_id = variant_id;
        this.variant_title = variant_title;
        this.variant_key = variant_key;
        this.price = price;
        this.total_amount_diamonds = total_amount_diamonds;
        this.abs = abs;
        this.crystal = crystal;
        this.glow = glow;
    }
}

export { Scan, Variant };
