const currency =[{
    "name": "Afghanistan",
    "country_code": "AF",
    "currency_code": "AFN",
    "currency_symbol": "؋",
    "flag": "🇦🇫"
  },
  {
    "name": "Albania",
    "country_code": "AL",
    "currency_code": "ALL",
    "currency_symbol": "L",
    "flag": "🇦🇱"
  },
  {
    "name": "Algeria",
    "country_code": "DZ",
    "currency_code": "DZD",
    "currency_symbol": "د.ج",
    "flag": "🇩🇿"
  },
  {
    "name": "Andorra",
    "country_code": "AD",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇦🇩"
  },
  {
    "name": "Angola",
    "country_code": "AO",
    "currency_code": "AOA",
    "currency_symbol": "Kz",
    "flag": "🇦🇴"
  },
  {
    "name": "Antigua and Barbuda",
    "country_code": "AG",
    "currency_code": "XCD",
    "currency_symbol": "$",
    "flag": "🇦🇬"
  },
  {
    "name": "Argentina",
    "country_code": "AR",
    "currency_code": "ARS",
    "currency_symbol": "$",
    "flag": "🇦🇷"
  },
  {
    "name": "Armenia",
    "country_code": "AM",
    "currency_code": "AMD",
    "currency_symbol": "֏",
    "flag": "🇦🇲"
  },
  {
    "name": "Australia",
    "country_code": "AU",
    "currency_code": "AUD",
    "currency_symbol": "$",
    "flag": "🇦🇺"
  },
  {
    "name": "Austria",
    "country_code": "AT",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇦🇹"
  },
  {
    "name": "Azerbaijan",
    "country_code": "AZ",
    "currency_code": "AZN",
    "currency_symbol": "₼",
    "flag": "🇦🇿"
  },
  {
    "name": "Bahamas",
    "country_code": "BS",
    "currency_code": "BSD",
    "currency_symbol": "$",
    "flag": "🇧🇸"
  },
  {
    "name": "Bahrain",
    "country_code": "BH",
    "currency_code": "BHD",
    "currency_symbol": ".د.ب",
    "flag": "🇧🇭"
  },
  {
    "name": "Bangladesh",
    "country_code": "BD",
    "currency_code": "BDT",
    "currency_symbol": "৳",
    "flag": "🇧🇩"
  },
  {
    "name": "Barbados",
    "country_code": "BB",
    "currency_code": "BBD",
    "currency_symbol": "$",
    "flag": "🇧🇧"
  },
  {
    "name": "Belarus",
    "country_code": "BY",
    "currency_code": "BYN",
    "currency_symbol": "Br",
    "flag": "🇧🇾"
  },
  {
    "name": "Belgium",
    "country_code": "BE",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇧🇪"
  },
  {
    "name": "Belize",
    "country_code": "BZ",
    "currency_code": "BZD",
    "currency_symbol": "BZ$",
    "flag": "🇧🇿"
  },
  {
    "name": "Benin",
    "country_code": "BJ",
    "currency_code": "XOF",
    "currency_symbol": "CFA",
    "flag": "🇧🇯"
  },
  {
    "name": "Bhutan",
    "country_code": "BT",
    "currency_code": "BTN",
    "currency_symbol": "Nu.",
    "flag": "🇧🇹"
  },
  {
    "name": "Bolivia",
    "country_code": "BO",
    "currency_code": "BOB",
    "currency_symbol": "Bs.",
    "flag": "🇧🇴"
  },
  {
    "name": "Bosnia and Herzegovina",
    "country_code": "BA",
    "currency_code": "BAM",
    "currency_symbol": "KM",
    "flag": "🇧🇦"
  },
  {
    "name": "Botswana",
    "country_code": "BW",
    "currency_code": "BWP",
    "currency_symbol": "P",
    "flag": "🇧🇼"
  },
  {
    "name": "Brazil",
    "country_code": "BR",
    "currency_code": "BRL",
    "currency_symbol": "R$",
    "flag": "🇧🇷"
  },
  {
    "name": "Brunei",
    "country_code": "BN",
    "currency_code": "BND",
    "currency_symbol": "$",
    "flag": "🇧🇳"
  },
  {
    "name": "Bulgaria",
    "country_code": "BG",
    "currency_code": "BGN",
    "currency_symbol": "лв",
    "flag": "🇧🇬"
  },
  {
    "name": "Burkina Faso",
    "country_code": "BF",
    "currency_code": "XOF",
    "currency_symbol": "CFA",
    "flag": "🇧🇫"
  },
  {
    "name": "Burundi",
    "country_code": "BI",
    "currency_code": "BIF",
    "currency_symbol": "FBu",
    "flag": "🇧🇮"
  },
  {
    "name": "Cabo Verde",
    "country_code": "CV",
    "currency_code": "CVE",
    "currency_symbol": "CVE",
    "flag": "🇨🇻"
  },
  {
    "name": "Cambodia",
    "country_code": "KH",
    "currency_code": "KHR",
    "currency_symbol": "៛",
    "flag": "🇰🇭"
  },
  {
    "name": "Cameroon",
    "country_code": "CM",
    "currency_code": "XAF",
    "currency_symbol": "FCFA",
    "flag": "🇨🇲"
  },
  {
    "name": "Canada",
    "country_code": "CA",
    "currency_code": "CAD",
    "currency_symbol": "$",
    "flag": "🇨🇦"
  },
  {
    "name": "Central African Republic",
    "country_code": "CF",
    "currency_code": "XAF",
    "currency_symbol": "FCFA",
    "flag": "🇨🇫"
  },
  {
    "name": "Chad",
    "country_code": "TD",
    "currency_code": "XAF",
    "currency_symbol": "FCFA",
    "flag": "🇹🇩"
  },
  {
    "name": "Chile",
    "country_code": "CL",
    "currency_code": "CLP",
    "currency_symbol": "$",
    "flag": "🇨🇱"
  },
  {
    "name": "China",
    "country_code": "CN",
    "currency_code": "CNY",
    "currency_symbol": "¥",
    "flag": "🇨🇳"
  },
  {
    "name": "Colombia",
    "country_code": "CO",
    "currency_code": "COP",
    "currency_symbol": "$",
    "flag": "🇨🇴"
  },
  {
    "name": "Comoros",
    "country_code": "KM",
    "currency_code": "KMF",
    "currency_symbol": "CF",
    "flag": "🇰🇲"
  },
  {
    "name": "Congo (Congo-Brazzaville)",
    "country_code": "CG",
    "currency_code": "XAF",
    "currency_symbol": "FCFA",
    "flag": "🇨🇬"
  },
  {
    "name": "Costa Rica",
    "country_code": "CR",
    "currency_code": "CRC",
    "currency_symbol": "₡",
    "flag": "🇨🇷"
  },
  {
    "name": "Croatia",
    "country_code": "HR",
    "currency_code": "HRK",
    "currency_symbol": "kn",
    "flag": "🇭🇷"
  },
  {
    "name": "Cuba",
    "country_code": "CU",
    "currency_code": "CUP",
    "currency_symbol": "₱",
    "flag": "🇨🇺"
  },
  {
    "name": "Cyprus",
    "country_code": "CY",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇨🇾"
  },
  {
    "name": "Czech Republic",
    "country_code": "CZ",
    "currency_code": "CZK",
    "currency_symbol": "Kč",
    "flag": "🇨🇿"
  },
  {
    "name": "Democratic Republic of the Congo",
    "country_code": "CD",
    "currency_code": "CDF",
    "currency_symbol": "FC",
    "flag": "🇨🇩"
  },
  {
    "name": "Denmark",
    "country_code": "DK",
    "currency_code": "DKK",
    "currency_symbol": "kr",
    "flag": "🇩🇰"
  },
  {
    "name": "Djibouti",
    "country_code": "DJ",
    "currency_code": "DJF",
    "currency_symbol": "Fdj",
    "flag": "🇩🇯"
  },
  {
    "name": "Dominica",
    "country_code": "DM",
    "currency_code": "XCD",
    "currency_symbol": "$",
    "flag": "🇩🇲"
  },
  {
    "name": "Dominican Republic",
    "country_code": "DO",
    "currency_code": "DOP",
    "currency_symbol": "RD$",
    "flag": "🇩🇴"
  },
//   {
//     "name": "East Timor (Timor-Leste)",
//     "country_code": "TL",
//     "currency_code": "USD",
//     "currency_symbol": "$",
//     "flag": "🇹🇱"
//   },
//   {
//     "name": "Ecuador",
//     "country_code": "EC",
//     "currency_code": "USD",
//     "currency_symbol": "$",
//     "flag": "🇪🇨"
//   },
  {
    "name": "Egypt",
    "country_code": "EG",
    "currency_code": "EGP",
    "currency_symbol": "E£",
    "flag": "🇪🇬"
  },
//   {
//     "name": "El Salvador",
//     "country_code": "SV",
//     "currency_code": "USD",
//     "currency_symbol": "$",
//     "flag": "🇸🇻"
//   },
  {
    "name": "Equatorial Guinea",
    "country_code": "GQ",
    "currency_code": "XAF",
    "currency_symbol": "FCFA",
    "flag": "🇬🇶"
  },
  {
    "name": "Eritrea",
    "country_code": "ER",
    "currency_code": "ERN",
    "currency_symbol": "Nfk",
    "flag": "🇪🇷"
  },
  {
    "name": "Estonia",
    "country_code": "EE",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇪🇪"
  },
  {
    "name": "Eswatini (fmr. 'Swaziland')",
    "country_code": "SZ",
    "currency_code": "SZL",
    "currency_symbol": "L",
    "flag": "🇸🇿"
  },
  {
    "name": "Ethiopia",
    "country_code": "ET",
    "currency_code": "ETB",
    "currency_symbol": "Br",
    "flag": "🇪🇹"
  },
  {
    "name": "Fiji",
    "country_code": "FJ",
    "currency_code": "FJD",
    "currency_symbol": "$",
    "flag": "🇫🇯"
  },
  {
    "name": "Finland",
    "country_code": "FI",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇫🇮"
  },
  {
    "name": "France",
    "country_code": "FR",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇫🇷"
  },
  {
    "name": "Gabon",
    "country_code": "GA",
    "currency_code": "XAF",
    "currency_symbol": "FCFA",
    "flag": "🇬🇦"
  },
  {
    "name": "Gambia",
    "country_code": "GM",
    "currency_code": "GMD",
    "currency_symbol": "D",
    "flag": "🇬🇲"
  },
  {
    "name": "Georgia",
    "country_code": "GE",
    "currency_code": "GEL",
    "currency_symbol": "₾",
    "flag": "🇬🇪"
  },
  {
    "name": "Germany",
    "country_code": "DE",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇩🇪"
  },
  {
    "name": "Ghana",
    "country_code": "GH",
    "currency_code": "GHS",
    "currency_symbol": "₵",
    "flag": "🇬🇭"
  },
  {
    "name": "Greece",
    "country_code": "GR",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇬🇷"
  },
  {
    "name": "Grenada",
    "country_code": "GD",
    "currency_code": "XCD",
    "currency_symbol": "$",
    "flag": "🇬🇩"
  },
  {
    "name": "Guatemala",
    "country_code": "GT",
    "currency_code": "GTQ",
    "currency_symbol": "Q",
    "flag": "🇬🇹"
  },
  {
    "name": "Guinea",
    "country_code": "GN",
    "currency_code": "GNF",
    "currency_symbol": "FG",
    "flag": "🇬🇳"
  },
  {
    "name": "Guinea-Bissau",
    "country_code": "GW",
    "currency_code": "XOF",
    "currency_symbol": "CFA",
    "flag": "🇬🇼"
  },
  {
    "name": "Guyana",
    "country_code": "GY",
    "currency_code": "GYD",
    "currency_symbol": "$",
    "flag": "🇬🇾"
  },
  {
    "name": "Haiti",
    "country_code": "HT",
    "currency_code": "HTG",
    "currency_symbol": "G",
    "flag": "🇭🇹"
  },
  {
    "name": "Honduras",
    "country_code": "HN",
    "currency_code": "HNL",
    "currency_symbol": "L",
    "flag": "🇭🇳"
  },
  {
    "name": "Hungary",
    "country_code": "HU",
    "currency_code": "HUF",
    "currency_symbol": "Ft",
    "flag": "🇭🇺"
  },
  {
    "name": "Iceland",
    "country_code": "IS",
    "currency_code": "ISK",
    "currency_symbol": "kr",
    "flag": "🇮🇸"
  },
  {
    "name": "India",
    "country_code": "IN",
    "currency_code": "INR",
    "currency_symbol": "₹",
    "flag": "🇮🇳"
  },
  {
    "name": "Indonesia",
    "country_code": "ID",
    "currency_code": "IDR",
    "currency_symbol": "Rp",
    "flag": "🇮🇩"
  },
  {
    "name": "Iran",
    "country_code": "IR",
    "currency_code": "IRR",
    "currency_symbol": "﷼",
    "flag": "🇮🇷"
  },
  {
    "name": "Iraq",
    "country_code": "IQ",
    "currency_code": "IQD",
    "currency_symbol": "ع.د",
    "flag": "🇮🇶"
  },
  {
    "name": "Ireland",
    "country_code": "IE",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇮🇪"
  },
  {
    "name": "Israel",
    "country_code": "IL",
    "currency_code": "ILS",
    "currency_symbol": "₪",
    "flag": "🇮🇱"
  },
  {
    "name": "Italy",
    "country_code": "IT",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇮🇹"
  },
  {
    "name": "Jamaica",
    "country_code": "JM",
    "currency_code": "JMD",
    "currency_symbol": "J$",
    "flag": "🇯🇲"
  },
  {
    "name": "Japan",
    "country_code": "JP",
    "currency_code": "JPY",
    "currency_symbol": "¥",
    "flag": "🇯🇵"
  },
  {
    "name": "Jordan",
    "country_code": "JO",
    "currency_code": "JOD",
    "currency_symbol": "د.ا",
    "flag": "🇯🇴"
  },
  {
    "name": "Kazakhstan",
    "country_code": "KZ",
    "currency_code": "KZT",
    "currency_symbol": "₸",
    "flag": "🇰🇿"
  },
  {
    "name": "Kenya",
    "country_code": "KE",
    "currency_code": "KES",
    "currency_symbol": "KSh",
    "flag": "🇰🇪"
  },
  {
    "name": "Kiribati",
    "country_code": "KI",
    "currency_code": "AUD",
    "currency_symbol": "$",
    "flag": "🇰🇮"
  },
  {
    "name": "Kuwait",
    "country_code": "KW",
    "currency_code": "KWD",
    "currency_symbol": "د.ك",
    "flag": "🇰🇼"
  },
  {
    "name": "Kyrgyzstan",
    "country_code": "KG",
    "currency_code": "KGS",
    "currency_symbol": "с",
    "flag": "🇰🇬"
  },
  {
    "name": "Laos",
    "country_code": "LA",
    "currency_code": "LAK",
    "currency_symbol": "₭",
    "flag": "🇱🇦"
  },
  {
    "name": "Latvia",
    "country_code": "LV",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇱🇻"
  },
  {
    "name": "Lebanon",
    "country_code": "LB",
    "currency_code": "LBP",
    "currency_symbol": "ل.ل",
    "flag": "🇱🇧"
  },
  {
    "name": "Lesotho",
    "country_code": "LS",
    "currency_code": "LSL",
    "currency_symbol": "L",
    "flag": "🇱🇸"
  },
  {
    "name": "Liberia",
    "country_code": "LR",
    "currency_code": "LRD",
    "currency_symbol": "$",
    "flag": "🇱🇷"
  },
  {
    "name": "Libya",
    "country_code": "LY",
    "currency_code": "LYD",
    "currency_symbol": "ل.د",
    "flag": "🇱🇾"
  },
  {
    "name": "Liechtenstein",
    "country_code": "LI",
    "currency_code": "CHF",
    "currency_symbol": "Fr.",
    "flag": "🇱🇮"
  },
  {
    "name": "Lithuania",
    "country_code": "LT",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇱🇹"
  },
  {
    "name": "Luxembourg",
    "country_code": "LU",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇱🇺"
  },
  {
    "name": "Madagascar",
    "country_code": "MG",
    "currency_code": "MGA",
    "currency_symbol": "Ar",
    "flag": "🇲🇬"
  },
  {
    "name": "Malawi",
    "country_code": "MW",
    "currency_code": "MWK",
    "currency_symbol": "MK",
    "flag": "🇲🇼"
  },
  {
    "name": "Malaysia",
    "country_code": "MY",
    "currency_code": "MYR",
    "currency_symbol": "RM",
    "flag": "🇲🇾"
  },
  {
    "name": "Maldives",
    "country_code": "MV",
    "currency_code": "MVR",
    "currency_symbol": "Rf",
    "flag": "🇲🇻"
  },
  {
    "name": "Mali",
    "country_code": "ML",
    "currency_code": "XOF",
    "currency_symbol": "CFA",
    "flag": "🇲🇱"
  },
  {
    "name": "Malta",
    "country_code": "MT",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇲🇹"
  },
//   {
//     "name": "Marshall Islands",
//     "country_code": "MH",
//     "currency_code": "USD",
//     "currency_symbol": "$",
//     "flag": "🇲🇭"
//   },
  {
    "name": "Mauritania",
    "country_code": "MR",
    "currency_code": "MRU",
    "currency_symbol": "UM",
    "flag": "🇲🇷"
  },
  {
    "name": "Mauritius",
    "country_code": "MU",
    "currency_code": "MUR",
    "currency_symbol": "₨",
    "flag": "🇲🇺"
  },
  {
    "name": "Mexico",
    "country_code": "MX",
    "currency_code": "MXN",
    "currency_symbol": "$",
    "flag": "🇲🇽"
  },
//   {
//     "name": "Micronesia",
//     "country_code": "FM",
//     "currency_code": "USD",
//     "currency_symbol": "$",
//     "flag": "🇫🇲"
//   },
  {
    "name": "Moldova",
    "country_code": "MD",
    "currency_code": "MDL",
    "currency_symbol": "L",
    "flag": "🇲🇩"
  },
  {
    "name": "Monaco",
    "country_code": "MC",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇲🇨"
  },
  {
    "name": "Mongolia",
    "country_code": "MN",
    "currency_code": "MNT",
    "currency_symbol": "₮",
    "flag": "🇲🇳"
  },
  {
    "name": "Montenegro",
    "country_code": "ME",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇲🇪"
  },
  {
    "name": "Morocco",
    "country_code": "MA",
    "currency_code": "MAD",
    "currency_symbol": "د.م.",
    "flag": "🇲🇦"
  },
  {
    "name": "Mozambique",
    "country_code": "MZ",
    "currency_code": "MZN",
    "currency_symbol": "MT",
    "flag": "🇲🇿"
  },
  {
    "name": "Myanmar (formerly Burma)",
    "country_code": "MM",
    "currency_code": "MMK",
    "currency_symbol": "K",
    "flag": "🇲🇲"
  },
  {
    "name": "Namibia",
    "country_code": "NA",
    "currency_code": "NAD",
    "currency_symbol": "$",
    "flag": "🇳🇦"
  },
  {
    "name": "Nauru",
    "country_code": "NR",
    "currency_code": "AUD",
    "currency_symbol": "$",
    "flag": "🇳🇷"
  },
  {
    "name": "Nepal",
    "country_code": "NP",
    "currency_code": "NPR",
    "currency_symbol": "रू",
    "flag": "🇳🇵"
  },
  {
    "name": "Netherlands",
    "country_code": "NL",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇳🇱"
  },
  {
    "name": "New Zealand",
    "country_code": "NZ",
    "currency_code": "NZD",
    "currency_symbol": "$",
    "flag": "🇳🇿"
  },
  {
    "name": "Nicaragua",
    "country_code": "NI",
    "currency_code": "NIO",
    "currency_symbol": "C$",
    "flag": "🇳🇮"
  },
  {
    "name": "Niger",
    "country_code": "NE",
    "currency_code": "XOF",
    "currency_symbol": "CFA",
    "flag": "🇳🇪"
  },
  {
    "name": "Nigeria",
    "country_code": "NG",
    "currency_code": "NGN",
    "currency_symbol": "₦",
    "flag": "🇳🇬"
  },
  {
    "name": "North Korea",
    "country_code": "KP",
    "currency_code": "KPW",
    "currency_symbol": "₩",
    "flag": "🇰🇵"
  },
  {
    "name": "North Macedonia",
    "country_code": "MK",
    "currency_code": "MKD",
    "currency_symbol": "ден",
    "flag": "🇲🇰"
  },
  {
    "name": "Norway",
    "country_code": "NO",
    "currency_code": "NOK",
    "currency_symbol": "kr",
    "flag": "🇳🇴"
  },
  {
    "name": "Oman",
    "country_code": "OM",
    "currency_code": "OMR",
    "currency_symbol": "ر.ع.",
    "flag": "🇴🇲"
  },
  {
    "name": "Pakistan",
    "country_code": "PK",
    "currency_code": "PKR",
    "currency_symbol": "₨",
    "flag": "🇵🇰"
  },
//   {
//     "name": "Palau",
//     "country_code": "PW",
//     "currency_code": "USD",
//     "currency_symbol": "$",
//     "flag": "🇵🇼"
//   },
  {
    "name": "Palestine State",
    "country_code": "PS",
    "currency_code": "ILS",
    "currency_symbol": "₪",
    "flag": "🇵🇸"
  },
  {
    "name": "Panama",
    "country_code": "PA",
    "currency_code": "PAB",
    "currency_symbol": "B/.",
    "flag": "🇵🇦"
  },
  {
    "name": "Papua New Guinea",
    "country_code": "PG",
    "currency_code": "PGK",
    "currency_symbol": "K",
    "flag": "🇵🇬"
  },
  {
    "name": "Paraguay",
    "country_code": "PY",
    "currency_code": "PYG",
    "currency_symbol": "₲",
    "flag": "🇵🇾"
  },
  {
    "name": "Peru",
    "country_code": "PE",
    "currency_code": "PEN",
    "currency_symbol": "S/.",
    "flag": "🇵🇪"
  },
  {
    "name": "Philippines",
    "country_code": "PH",
    "currency_code": "PHP",
    "currency_symbol": "₱",
    "flag": "🇵🇭"
  },
  {
    "name": "Poland",
    "country_code": "PL",
    "currency_code": "PLN",
    "currency_symbol": "zł",
    "flag": "🇵🇱"
  },
  {
    "name": "Portugal",
    "country_code": "PT",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇵🇹"
  },
  {
    "name": "Qatar",
    "country_code": "QA",
    "currency_code": "QAR",
    "currency_symbol": "ر.ق",
    "flag": "🇶🇦"
  },
  {
    "name": "Romania",
    "country_code": "RO",
    "currency_code": "RON",
    "currency_symbol": "lei",
    "flag": "🇷🇴"
  },
  {
    "name": "Russia",
    "country_code": "RU",
    "currency_code": "RUB",
    "currency_symbol": "₽",
    "flag": "🇷🇺"
  },
  {
    "name": "Rwanda",
    "country_code": "RW",
    "currency_code": "RWF",
    "currency_symbol": "FRw",
    "flag": "🇷🇼"
  },
  {
    "name": "Saint Kitts and Nevis",
    "country_code": "KN",
    "currency_code": "XCD",
    "currency_symbol": "$",
    "flag": "🇰🇳"
  },
  {
    "name": "Saint Lucia",
    "country_code": "LC",
    "currency_code": "XCD",
    "currency_symbol": "$",
    "flag": "🇱🇨"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "country_code": "VC",
    "currency_code": "XCD",
    "currency_symbol": "$",
    "flag": "🇻🇨"
  },
  {
    "name": "Samoa",
    "country_code": "WS",
    "currency_code": "WST",
    "currency_symbol": "WS$",
    "flag": "🇼🇸"
  },
  {
    "name": "San Marino",
    "country_code": "SM",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇸🇲"
  },
  {
    "name": "Sao Tome and Principe",
    "country_code": "ST",
    "currency_code": "STN",
    "currency_symbol": "Db",
    "flag": "🇸🇹"
  },
  {
    "name": "Saudi Arabia",
    "country_code": "SA",
    "currency_code": "SAR",
    "currency_symbol": "ر.س",
    "flag": "🇸🇦"
  },
  {
    "name": "Senegal",
    "country_code": "SN",
    "currency_code": "XOF",
    "currency_symbol": "CFA",
    "flag": "🇸🇳"
  },
  {
    "name": "Serbia",
    "country_code": "RS",
    "currency_code": "RSD",
    "currency_symbol": "дин.",
    "flag": "🇷🇸"
  },
  {
    "name": "Seychelles",
    "country_code": "SC",
    "currency_code": "SCR",
    "currency_symbol": "SRe",
    "flag": "🇸🇨"
  },
  {
    "name": "Sierra Leone",
    "country_code": "SL",
    "currency_code": "SLL",
    "currency_symbol": "Le",
    "flag": "🇸🇱"
  },
  {
    "name": "Singapore",
    "country_code": "SG",
    "currency_code": "SGD",
    "currency_symbol": "$",
    "flag": "🇸🇬"
  },
  {
    "name": "Slovakia",
    "country_code": "SK",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇸🇰"
  },
  {
    "name": "Slovenia",
    "country_code": "SI",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇸🇮"
  },
  {
    "name": "Solomon Islands",
    "country_code": "SB",
    "currency_code": "SBD",
    "currency_symbol": "$",
    "flag": "🇸🇧"
  },
  {
    "name": "Somalia",
    "country_code": "SO",
    "currency_code": "SOS",
    "currency_symbol": "Sh.So.",
    "flag": "🇸🇴"
  },
  {
    "name": "South Africa",
    "country_code": "ZA",
    "currency_code": "ZAR",
    "currency_symbol": "R",
    "flag": "🇿🇦"
  },
  {
    "name": "South Korea",
    "country_code": "KR",
    "currency_code": "KRW",
    "currency_symbol": "₩",
    "flag": "🇰🇷"
  },
  {
    "name": "South Sudan",
    "country_code": "SS",
    "currency_code": "SSP",
    "currency_symbol": "£",
    "flag": "🇸🇸"
  },
  {
    "name": "Spain",
    "country_code": "ES",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇪🇸"
  },
  {
    "name": "Sri Lanka",
    "country_code": "LK",
    "currency_code": "LKR",
    "currency_symbol": "₨",
    "flag": "🇱🇰"
  },
  {
    "name": "Sudan",
    "country_code": "SD",
    "currency_code": "SDG",
    "currency_symbol": "ج.س.",
    "flag": "🇸🇩"
  },
  {
    "name": "Suriname",
    "country_code": "SR",
    "currency_code": "SRD",
    "currency_symbol": "$",
    "flag": "🇸🇷"
  },
  {
    "name": "Sweden",
    "country_code": "SE",
    "currency_code": "SEK",
    "currency_symbol": "kr",
    "flag": "🇸🇪"
  },
  {
    "name": "Switzerland",
    "country_code": "CH",
    "currency_code": "CHF",
    "currency_symbol": "Fr.",
    "flag": "🇨🇭"
  },
  {
    "name": "Syria",
    "country_code": "SY",
    "currency_code": "SYP",
    "currency_symbol": "£S",
    "flag": "🇸🇾"
  },
  {
    "name": "Tajikistan",
    "country_code": "TJ",
    "currency_code": "TJS",
    "currency_symbol": "SM",
    "flag": "🇹🇯"
  },
  {
    "name": "Tanzania",
    "country_code": "TZ",
    "currency_code": "TZS",
    "currency_symbol": "Sh",
    "flag": "🇹🇿"
  },
  {
    "name": "Thailand",
    "country_code": "TH",
    "currency_code": "THB",
    "currency_symbol": "฿",
    "flag": "🇹🇭"
  },
  {
    "name": "Togo",
    "country_code": "TG",
    "currency_code": "XOF",
    "currency_symbol": "CFA",
    "flag": "🇹🇬"
  },
  {
    "name": "Tonga",
    "country_code": "TO",
    "currency_code": "TOP",
    "currency_symbol": "T$",
    "flag": "🇹🇴"
  },
  {
    "name": "Trinidad and Tobago",
    "country_code": "TT",
    "currency_code": "TTD",
    "currency_symbol": "TT$",
    "flag": "🇹🇹"
  },
  {
    "name": "Tunisia",
    "country_code": "TN",
    "currency_code": "TND",
    "currency_symbol": "د.ت",
    "flag": "🇹🇳"
  },
  {
    "name": "Turkey",
    "country_code": "TR",
    "currency_code": "TRY",
    "currency_symbol": "₺",
    "flag": "🇹🇷"
  },
  {
    "name": "Turkmenistan",
    "country_code": "TM",
    "currency_code": "TMT",
    "currency_symbol": "T",
    "flag": "🇹🇲"
  },
  {
    "name": "Tuvalu",
    "country_code": "TV",
    "currency_code": "AUD",
    "currency_symbol": "$",
    "flag": "🇹🇻"
  },
  {
    "name": "Uganda",
    "country_code": "UG",
    "currency_code": "UGX",
    "currency_symbol": "USh",
    "flag": "🇺🇬"
  },
  {
    "name": "Ukraine",
    "country_code": "UA",
    "currency_code": "UAH",
    "currency_symbol": "₴",
    "flag": "🇺🇦"
  },
  {
    "name": "United Arab Emirates",
    "country_code": "AE",
    "currency_code": "AED",
    "currency_symbol": "د.إ",
    "flag": "🇦🇪"
  },
  {
    "name": "United Kingdom",
    "country_code": "GB",
    "currency_code": "GBP",
    "currency_symbol": "£",
    "flag": "🇬🇧"
  },
  {
    "name": "United States of America",
    "country_code": "US",
    "currency_code": "USD",
    "currency_symbol": "$",
    "flag": "🇺🇸"
  },
  {
    "name": "Uruguay",
    "country_code": "UY",
    "currency_code": "UYU",
    "currency_symbol": "$U",
    "flag": "🇺🇾"
  },
  {
    "name": "Uzbekistan",
    "country_code": "UZ",
    "currency_code": "UZS",
    "currency_symbol": "лв",
    "flag": "🇺🇿"
  },
  {
    "name": "Vanuatu",
    "country_code": "VU",
    "currency_code": "VUV",
    "currency_symbol": "VT",
    "flag": "🇻🇺"
  },
  {
    "name": "Vatican City",
    "country_code": "VA",
    "currency_code": "EUR",
    "currency_symbol": "€",
    "flag": "🇻🇦"
  },
  {
    "name": "Venezuela",
    "country_code": "VE",
    "currency_code": "VES",
    "currency_symbol": "Bs.",
    "flag": "🇻🇪"
  },
  {
    "name": "Vietnam",
    "country_code": "VN",
    "currency_code": "VND",
    "currency_symbol": "₫",
    "flag": "🇻🇳"
  },
  {
    "name": "Yemen",
    "country_code": "YE",
    "currency_code": "YER",
    "currency_symbol": "﷼",
    "flag": "🇾🇪"
  },
  {
    "name": "Zambia",
    "country_code": "ZM",
    "currency_code": "ZMW",
    "currency_symbol": "ZK",
    "flag": "🇿🇲"
  },
  {
    "name": "Zimbabwe",
    "country_code": "ZW",
    "currency_code": "ZWL",
    "currency_symbol": "$",
    "flag": "🇿🇼"
  }];

  export default currency;