
export class UserData {
    constructor({ email, fcm_token, profile_picture, name, userId, token }) {
        this.email = email;
        this.fcm_token = fcm_token;
        this.profile_picture = profile_picture;
        this.name = name;
        this.userId = userId;
        this.token = token;
    }
}
