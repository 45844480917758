import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { FaRegCirclePlay, FaRegCirclePause } from "react-icons/fa6";
import { RiEditCircleFill } from "react-icons/ri";
import { MdOutlineStackedBarChart } from "react-icons/md";
import { AiFillCloseSquare } from "react-icons/ai";
import { RxReload } from "react-icons/rx";
import { LuRadar } from "react-icons/lu";
import { RxDimensions } from "react-icons/rx";
import { CiMenuKebab } from "react-icons/ci";
import { IoDiamondOutline } from "react-icons/io5";
import Header from "../../components/common/header.js";
import imagePaths from "../../assets/imagePaths.js";
import {
  convertSecondsToHours,
  convertSecondsToHoursInner,
  convertSecondsToHoursInnerCompleted,
  convertSecondsToHoursLive,
  convertSecondsToHoursOnly,
  convertSecondsToMinutesOnly,
  convertSecondsToSecondsOnly,
  convertText,
  formatNumber,
  logMessage,
} from "../../utils/helpers.js";
import api from "../../api/api.js";
import { ApiResponse } from "../../models/ApiResponseModel.js";
import { Project } from "../../models/projectModel.js";
import paths from "../../utils/constants.js";
import { IoIosWarning } from "react-icons/io";
const ProjectDetail = ({ startLoading, stopLoading }) => {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [liveTimer, setLiveTimer] = useState(0);
  const [showEditPopup, setEditShowPopup] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project_id");
  const [projectDetail, setProjectDetail] = useState(new Project({}));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deleteProjectConfirmation, setDeleteProjectConfirmation] =
    useState(false);
  const [selectedHours, setSelectedHours] = useState(0);
  const [timerStatus, setTimerStatus] = useState("");
  const [selectedMinutes, setSelectedMinutes] = useState(0);
  const [selectedSeconds, setSelectedSeconds] = useState(0);

  const handleMinutesChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (value > 59) {
      alert("invalid seconds value");
    } else {
      setSelectedMinutes(value);
    }
  };
  const handleAnyEditClick = (e) => {
    logMessage("clickedinside");
    e.preventDefault();
  };
  const handleSecondsChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (value > 59) {
      alert("invalid seconds value");
    } else {
      setSelectedSeconds(value);
    }
  };

  const togglePopup = () => {
    logMessage("toggleclicked");
    setShowPopup(!showPopup);
    logMessage(showPopup);
  };
  const editTogglePopup = () => {
    logMessage("toggleclicked");
    setEditShowPopup(!showEditPopup);
  };
  const handleUpdateStatus = async () => {
    // if (status == "ongoing") {
    logMessage("handle update status clciked");
    togglePopup();
    // }
  };
  const updateProjectStatus = async () => {
    try {
      startLoading();
      const data = {
        project_id: projectId,
      };
      const response = await api.updateProjectStatus(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        togglePopup();
        fetchProjectDetail();
        stopLoading();
        // setShowUI(true);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const handleTimer = async (type, project_id) => {
    if (type == "stop") {
      stop_timer(project_id);
    } else {
      start_timer(project_id);
    }
    logMessage("handle timer clicked");
  };

  const start_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.startTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        fetchProjectDetail();
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const stop_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.stopTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        fetchProjectDetail();
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const openMenu = () => {
    logMessage("clicked");
    setIsMenuOpen(!isMenuOpen);
  };
  const fetchProjectDetail = async () => {
    try {
      startLoading();
      const data = {
        project_id: projectId,
      };
      const response = await api.getProjectDetail(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        logMessage(response.data);
        setProjectDetail(response.data);
        const hoursOnly = await convertSecondsToHoursOnly(
          response.data.totalDuration
        );
        const minutesonly = await convertSecondsToMinutesOnly(
          response.data.totalDuration
        );
        const secondsOnly = await convertSecondsToSecondsOnly(
          response.data.totalDuration
        );
        setSelectedHours(hoursOnly);
        setSelectedMinutes(minutesonly);
        setSelectedSeconds(secondsOnly);
        setTimerStatus(response.data.timer_status);
        logMessage(response.data.timer_status);
        if (response.data.timer_status === "started") {
          logMessage("livetimer");
          setLiveTimer(response.data.totalDuration);
        }
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    logMessage(liveTimer);
    if (liveTimer !== 0) {
      const intervalId = setInterval(() => {
        setLiveTimer(liveTimer + 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [liveTimer]);

  const handleClickOutside = (event) => {
    logMessage("outsideclicked");
    if (
      event.target.classList.contains("menuInner") ||
      event.target.classList.contains("dropbtn") ||
      event.target.classList.contains("editpopup")
    ) {
    } else {
      logMessage("inside menu outside");
      setIsMenuOpen(false);
      // setEditShowPopup(false)
    }
  };
  const callProjectConfirmation = () => {
    setDeleteProjectConfirmation(!deleteProjectConfirmation);
  };
  const goToEdit = async () => {
    navigate(paths.EDIT_PROJECT + "?project_id=" + projectId);
  };
  const deleteProject = async () => {
    try {
      startLoading();
      const data = {
        project_id: projectId,
      };
      const response = await api.deleteProjectData(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        navigate(paths.PROJECT_SCREEN);
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };

  const editTimer = async () => {
    try {
      const stopTimerData = {
        project_id: projectId,
      };
      if (timerStatus == "started") {
        await api.stopTimer(stopTimerData);
      }
      startLoading();
      const duration =
        selectedHours * 3600 + selectedMinutes * 60 + selectedSeconds;
      logMessage(duration);
      const data = {
        project_id: projectId,
        timer: duration,
      };
      const response = await api.editTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        window.location.reload();
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const resetTimer = async () => {
    try {
      startLoading();
      const stopTimerData = {
        project_id: projectId,
      };
      if (timerStatus == "started") {
        await api.stopTimer(stopTimerData);
      }

      const duration = 0;
      logMessage(duration);
      const data = {
        project_id: projectId,
        timer: duration,
      };
      const response = await api.editTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        window.location.reload();
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    logMessage(projectId);
    logMessage(deleteProjectConfirmation);
    // showDeleteProjectConfirmation(false)
    fetchProjectDetail();

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <div className="full_page_inner">
      <Header />
      <div className="detail_outer_container">
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup newsortpop">
              <div className="popup_header">
                <h2>Is the project completed?</h2>
              </div>
              <div className="popbodycontent">
                <button className="delete-button" onClick={updateProjectStatus}>
                  Submit
                </button>
                <br></br>
                <button className="cancel-button" onClick={togglePopup}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {deleteProjectConfirmation && (
          <div className="popup-overlay">
            <div className="popup">
              <h2>Delete Project</h2>
              <p>Are you sure you want to delete project?</p>
              <div className="warning">
                <strong>
                  <IoIosWarning /> Warning
                </strong>
                <p>
                  By deleting the project you will lose your project data and
                  its media.
                </p>
              </div>
              <button className="delete-button" onClick={deleteProject}>
                Delete Project
              </button>
              <button
                className="cancel-button"
                onClick={callProjectConfirmation}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {showEditPopup && (
          <div className="popup-overlay">
            <div className="popup newsortpop editpopup">
              <div className="popup_header">
                <h2>Update Logged Timer</h2>
                <span
                  onClick={editTogglePopup}
                  className="crossicon"
                  style={{ color: "#000", fontSize: "26px" }}
                >
                  <AiFillCloseSquare />
                </span>
              </div>

              <div className="popbodycontent">
                <div className="timereditor">
                  <div className="hoursbox">
                    <input
                      type="number"
                      value={selectedHours}
                      onChange={(e) => setSelectedHours(e.target.value)}
                    ></input>
                    <span>Hours</span>
                  </div>
                  <div className="minutesbox">
                    <input
                      type="number"
                      value={selectedMinutes}
                      onChange={handleMinutesChange}
                    ></input>
                    <span>Minutes</span>
                  </div>
                  <div className="secondsbox">
                    <input
                      type="number"
                      value={selectedSeconds}
                      onChange={handleSecondsChange}
                    ></input>
                    <span>Second</span>
                  </div>
                </div>
                <button className="delete-button" onClick={editTimer}>
                  Submit
                </button>
                <br></br>
                <button className="reset-button" onClick={resetTimer}>
                  <RxReload /> Reset
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="detail_inner_container projectDetail">
        {timerStatus != "" && (
          <div className="detail_left_container left_inner_detail">
            <div className="imageContainer">
              <img alt="project_image" src={projectDetail.image[0]}></img>
            </div>

            {projectDetail.project_status !== "completed" ? (
              <>
                <div className="logTimerContainer">
                  <div className="time_inner">
                    <p style={{ display: "flex", fontSize: "18px" }}>
                      Logged time:{" "}
                      <RiEditCircleFill
                        onClick={editTogglePopup}
                        style={{ fontSize: "18px" }}
                      />
                    </p>
                    <span style={{ fontSize: "18px" }}>
                      {projectDetail.timer_status === "started" ? (
                        <> {convertSecondsToHoursLive(liveTimer)}</>
                      ) : (
                        <>
                          {convertSecondsToHoursInner(
                            projectDetail.totalDuration
                          )}
                          <br></br>
                          <span
                            style={{ textAlign: "center", fontSize: "14px" }}
                          >
                            {" "}
                            Hr Min Sec
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                  <div className="actionblock">
                    <div className="play_button">
                      {projectDetail.timer_status === "started" ? (
                        <FaRegCirclePause
                          className="timer_duration"
                          onClick={(event) => {
                            handleTimer("stop", projectId);
                          }}
                        />
                      ) : (
                        <FaRegCirclePlay
                          className="timer_duration"
                          onClick={(event) => {
                            handleTimer("start", projectId);
                          }}
                        />
                      )}
                    </div>
                    {projectDetail.project_status === "ongoing" && (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={handleUpdateStatus}
                        className="projectaction"
                      >
                        Mark As Completed
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="logTimerContainer completedcontainer">
                <h2>Completed Project</h2>
                <p>
                  Total time taken while completing is{" "}
                  {convertSecondsToHoursInnerCompleted(
                    projectDetail.totalDuration
                  )}
                </p>
              </div>
            )}
          </div>
        )}
         {timerStatus != "" && (
          <div className="detail_right_container">
            <h2 className="main_design_name">
              {" "}
              {projectDetail.design}
              <CiMenuKebab onClick={openMenu} />
            </h2>
            {isMenuOpen && (
              <div
                className="dropdown-content"
                style={{ top: "140px", right: "94px" }}
              >
                <p className="menuInner" onClick={goToEdit}>
                  Edit project
                </p>
                <p className="menuInner" onClick={callProjectConfirmation}>
                  Delete project
                </p>
              </div>
            )}
            <h2 className="artist_name">© {projectDetail.artist}</h2>
            <div className="price">
              {projectDetail.price} {projectDetail.currency_code}
            </div>

            <div className="details_box">
              {timerStatus != "" && (
                <div
                  className="status_box"
                  style={{
                    backgroundImage: `url(${
                      projectDetail.project_status === "completed"
                        ? imagePaths.background_completed
                        : projectDetail.project_status === "ongoing"
                        ? imagePaths.background_ongoing
                        : projectDetail.project_status === "not_started"
                        ? imagePaths.background_not_started
                        : ""
                    })`,
                  }}
                >
                  <LuRadar />
                  <span className={`status ${projectDetail.project_status}`}>
                    {convertText(projectDetail.project_status)}
                  </span>
                </div>
              )}
              <h3>Dreamer Designs</h3>
              <p>
                {" "}
                <RxDimensions /> Size: {projectDetail.canvas_width} x{" "}
                {projectDetail.canvas_height}cm
              </p>
              <br></br>
              <p>
                <IoDiamondOutline />
                Diamonds:{" "}
                {projectDetail.diamond_type.charAt(0).toUpperCase() +
                  projectDetail.diamond_type.slice(1).toLowerCase()}{" "}
                with {projectDetail.no_of_color} colours
                {projectDetail.abs > 0 &&
                  ` including ${projectDetail.abs} AB’s`}
                {projectDetail.glow > 0 && `, ${projectDetail.glow} glow`}
                {projectDetail.crystal > 0 &&
                  `, ${projectDetail.crystal} crystal drills`}
              </p>

              <p>
                <MdOutlineStackedBarChart />
                {projectDetail.total_amount_diamonds < 2500
                  ? `Beginner level with ${formatNumber(
                      projectDetail.total_amount_diamonds
                    )} diamonds`
                  : `Pro level with ${formatNumber(
                      projectDetail.total_amount_diamonds
                    )} diamonds`}
              </p>
            </div>
            {projectDetail.tags.length > 0 && (
              <div className="tags">
                <h3>Tags</h3>
                {projectDetail.tags.map((tag, index) => (
                  <span key={index}>{tag}</span>
                ))}
              </div>
            )}
            {projectDetail.extraNotes && (
              <p className="detail_line">{projectDetail.extraNotes}</p>
            )}
          </div>
         )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
