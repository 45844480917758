const imagePaths = {
    intro_background: require('../images/newintro.png'),
    diamond_img: require('../images/newlogo.png'),
    text_icon: require('../images/dia_icon.png'),
    intro2: require('../images/intro2.png'),
    intro3: require('../images/intro3.png'),
    intro4: require('../images/intro4.png'),
    auth_background: require('../images/authback.png'),
    dreamer_logo: require('../images/mainlogo.png'),
    dreamer_logo_new: require('../images/dreamerlatest.png'),
    auth_side_image: require('../images/auth_side_image.png'),
    forgot_side_image: require('../images/forgot-side-image.png'),
    otp_side_image: require('../images/otp_screen.png'),
    change_password_side: require('../images/changePassword.png'),
    dreamer_header: require('../images/dreamer_header.png'),
    dashboard_background: require('../images/dashboard-back.png'),
    profile: require('../images/profile.png'),
    black_diamond: require('../images/black-diamond.png'),
    background_completed: require('../images/exportcompleted.png'),
    background_ongoing: require('../images/exportongoing.png'),
    background_not_started: require('../images/exportnotstarted.png'),
    grid_one: require('../images/gridone.png'),
    grid_two: require('../images/first.png'),
    grid_three: require('../images/second.png'),
    grid_four: require('../images/third.png'),
    grid_five: require('../images/fourth.png'),
    demo_profile: require('../images/default_profile.png'),
    scanner: require('../images/scanner.png'),
    tree:require('../images/g10.png'),
    nofile:require('../images/nofile.png'),
    profile_back:require('../images/profileback.png'),
    play_svg:require('../images/playbutton.svg'),
    play:require('../images/play.png'),
    shuffle:require('../images/shuffle3.png'),
    logohorizontal:require('../images/logo-horizontal.png'),
    profilebackgrid:require('../images/profilebacknew.png')
  };
  
  export default imagePaths;