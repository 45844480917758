class Project {
    constructor({
        image = [],
        special_status = false,
        abs = 0,
        glow = 0,
        crystal = 0,
        startDate = "",
        endDate = "",
        extraNotes = "",
        tags = [],
        timer_status = "paused",
        project_status = "ongoing",
        totalDuration = 0,
        _id = "",
        userId = "",
        artist = "",
        design = "",
        brand = "",
        canvas_width = 0,
        canvas_height = 0,
        diamond_type = "Round",
        no_of_color = 0,
        total_amount_diamonds = 0,
        price = 0.0,
        country_code = "US",
        currency_code = "usd",
        created_at = 0,
        __v = 0
    }) {
        this.image = image;
        this.special_status = special_status;
        this.abs = abs;
        this.glow = glow;
        this.crystal = crystal;
        this.startDate = startDate;
        this.endDate = endDate;
        this.extraNotes = extraNotes;
        this.tags = tags;
        this.timer_status = timer_status;
        this.project_status = project_status;
        this.totalDuration = totalDuration;
        this._id = _id;
        this.userId = userId;
        this.artist = artist;
        this.design = design;
        this.brand = brand;
        this.canvas_width = canvas_width;
        this.canvas_height = canvas_height;
        this.diamond_type = diamond_type;
        this.no_of_color = no_of_color;
        this.total_amount_diamonds = total_amount_diamonds;
        this.price = price;
        this.currency_code = currency_code;
        this.country_code = country_code;
        this.created_at = created_at;
        this.__v = __v;
    }
}

class DataModel {
    constructor({
        projects = [],
        totalPages = 0,
        currentPage = 1,
        totalProjects = 0,
        hasNextPage = false
    }) {
        this.projects = projects.map(project => new Project(project));
        this.totalPages = totalPages;
        this.currentPage = currentPage;
        this.totalProjects = totalProjects;
        this.hasNextPage = hasNextPage;
    }
}

export { Project, DataModel };
