import React from "react";
// import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {

  // const navigate = useNavigate();

  // const handleBack = () => {
  //   navigate(-1);
  // };

  return (
    <div className="empty-screen">
      <div className="inner_terms_content" style={{ color: "#323232" }}>
        <div className="inner_header">
        </div>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            This privacy policy applies to the Diamond Diaries app (hereby referred
            to as the "Application") for mobile devices created by Dreamer Networks
            INC. (hereby referred to as the "Service Provider").
          </span>
        </p>
        <p><strong>Information Collection and Use</strong></p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            The Application collects information when you download and use it. This
            information may include:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400, color:"#323232" }}>
            <span style={{ fontWeight: 400, color:"#323232" }}>
              Your device's Internet Protocol address (e.g., IP address)
            </span>
          </li>
          <li style={{ fontWeight: 400, color:"#323232" }}>
            <span style={{ fontWeight: 400, color:"#323232" }}>
              The pages of the Application that you visit, the time and date of
              your visit, the time spent on those pages
            </span>
          </li>
          <li style={{ fontWeight: 400, color:"#323232" }}>
            <span style={{ fontWeight: 400, color:"#323232" }}>The time spent on the Application</span>
          </li>
          <li style={{ fontWeight: 400, color:"#323232" }}>
            <span style={{ fontWeight: 400, color:"#323232" }}>
              The operating system you use on your mobile device
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            The Application does not gather precise information about the location
            of your mobile device.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            The Service Provider may use the information you provide to contact you
            from time to time with important information, required notices, and
            marketing promotions.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            For a better experience while using the Application, the Service
            Provider may require you to provide certain personally identifiable
            information, including but not limited to Full Name, Email. The
            information that the Service Provider requests will be retained by them
            and used as described in this privacy policy.
          </span>
        </p>
        <p><strong>Third Party Access</strong></p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            Only aggregated, anonymized data is periodically transmitted to
            external services to aid the Service Provider in improving the
            Application and their service. The Service Provider may share your
            information with third parties as described in this privacy
            statement.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            Please note that the Application utilizes third-party services that
            have their own Privacy Policy regarding data handling. Below are the
            links to the Privacy Policy of the third-party service providers used by
            the Application:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400, color:"#323232" }}>
            <a href="https://policies.google.com/terms">
              <span style={{ fontWeight: 400, color:"#1500ef" }}>Google Play Services</span>
            </a>
          </li>
          <li style={{ fontWeight: 400, color:"#323232" }}>
            <a href="https://firebase.google.com/terms/crashlytics">
              <span style={{ fontWeight: 400, color:"#1500ef" }}>Firebase Crashlytics</span>
            </a>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            The Service Provider may disclose User Provided and Automatically
            Collected Information:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400, color:"#323232" }}>
            <span style={{ fontWeight: 400, color:"#323232" }}>
              as required by law, such as to comply with a subpoena or similar
              legal process;
            </span>
          </li>
          <li style={{ fontWeight: 400, color:"#323232" }}>
            <span style={{ fontWeight: 400, color:"#323232" }}>
              when they believe in good faith that disclosure is necessary to
              protect their rights, protect your safety or the safety of others,
              investigate fraud, or respond to a government request;
            </span>
          </li>
          <li style={{ fontWeight: 400, color:"#323232" }}>
            <span style={{ fontWeight: 400, color:"#323232" }}>
              with trusted service providers who work on their behalf, do not have
              independent use of the information, and have agreed to adhere to the
              rules set forth in this privacy statement.
            </span>
          </li>
        </ul>
        <p><strong>Opt-Out Rights</strong></p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            You can stop all collection of information by the Application easily by
            uninstalling it. You may use the standard uninstall processes available
            as part of your mobile device or via the mobile application marketplace
            or network.
          </span>
        </p>
        <p><strong>Data Retention Policy</strong></p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            The Service Provider will retain User Provided data for as long as you
            use the Application and for a reasonable time thereafter. If you would
            like them to delete User Provided Data that you have provided via the
            Application, please contact them at support@dreamerdesigns.com, and they
            will respond within a reasonable time.
          </span>
        </p>
        <p><strong>Children</strong></p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            The Service Provider does not use the Application to knowingly solicit
            data from or market to children under the age of 13.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            The Application does not address anyone under the age of 13. The
            Service Provider does not knowingly collect personally identifiable
            information from children under 13 years of age. If the Service Provider
            discovers that a child under 13 has provided personal information, they
            will immediately delete it from their servers. If you are a parent or
            guardian and you are aware that your child has provided us with personal
            information, please contact the Service Provider
            (support@dreamerdesigns.com) so that they can take necessary
            actions.
          </span>
        </p>
        <p><strong>Security</strong></p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            The Service Provider is concerned about safeguarding the
            confidentiality of your information. They provide physical, electronic,
            and procedural safeguards to protect the information they process and
            maintain.
          </span>
        </p>
        <p><strong>Changes</strong></p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            This Privacy Policy may be updated from time to time for any reason.
            The Service Provider will notify you of any changes by updating this
            page with the new Privacy Policy. You are advised to consult this
            Privacy Policy regularly for any changes, as continued use is deemed
            approval of all changes.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            This privacy policy is effective as of 2024-06-19.
          </span>
        </p>
        <p><strong>Your Consent</strong></p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            By using the Application, you consent to the processing of your
            information as set forth in this Privacy Policy now and as amended by
            us.
          </span>
        </p>
        <p><strong>Contact Us</strong></p>
        <p>
          <span style={{ fontWeight: 400, color:"#323232" }}>
            If you have any questions regarding privacy while using the Application
            or about the practices described in this policy, please contact the
            Service Provider via email at support@dreamerdesigns.com.
          </span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
