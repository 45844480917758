// src/data/introData.js
import imagePaths from '../assets/imagePaths';

const introData = [
    {

        content: {
            images_path: imagePaths.diamond_img,
            heading: 'Welcome to Diamond Dreamers by Dreamer Designs',
            listItems: ['Your new digital logbook to keep your diamond painting projects organized.']
        }
    },
    {

        content: {
            images_path: imagePaths.intro2,
            heading: 'Home Analytics',
            listItems: ['Total project entries', 'Total hours logged (each project has it’s own timer)', 'Total diamonds placed on canvas', 'Total projects not started']
        }
    },
    {

        content: {
            images_path: imagePaths.intro3,
            heading: 'Projects',
            listItems: ['Create new projects', 'Edit or Delete Projects', 'Organize projects (competed, work-in-progress or not started)', 'Custom sort your projects', 'Advance search for your projects']
        }
    },
    {

        content: {
            images_path: imagePaths.intro4,
            heading: 'Shuffle',
            subheading: 'Not sure which project to start next?',
            listItems: ['Like a slot machine, click the Shuffle button and let us spin the next project for you to start!']
        }
    },
];

export default introData;
