import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "firebase/compat/storage";
import { logMessage } from '../utils/helpers';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAz6ZzXQmahvVcEdNSma1FONiG4Rr20Euk",
  authDomain: "gemlogs-app.firebaseapp.com",
  projectId: "gemlogs-app",
  storageBucket: "gemlogs-app.appspot.com",
  messagingSenderId: "439913861495",
  appId: "1:439913861495:web:031dbf5ace3f85ee1a1172"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const uploadImage = async (image) => {
    try {
      logMessage("goin here");
      const timestamp = Date.now();
      const imageName = `${timestamp}_${image.name}`;
      logMessage("goin here 2");
      const storageRef = firebase.storage().ref().child("web_user_projects");
      logMessage("goin here 2.2");
      const imageRef = storageRef.child(imageName);
      logMessage("goin here 3");
      await imageRef.put(image);
      logMessage("goin here 4");
      const imageUrl = await imageRef.getDownloadURL();
      logMessage(imageUrl);
      return imageUrl;
    } catch (error) {
      return "";
    }
  };
  