// src/screens/IntroScreen.js

import React, { useState, useEffect, useRef } from 'react';
import { logMessage } from '../../utils/helpers';
import imagePaths from '../../assets/imagePaths';
import './authScreen.css';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import paths from '../../utils/constants';
import { ApiResponse } from '../../models/ApiResponseModel';

const OtpScreen = ({ startLoading, stopLoading }) => {
    const inputRefs = useRef([]);
    const [otp, setOtp] = useState(Array(4).fill(''));
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate(paths.HOME_SCREEN);
        }
    }, [navigate]);

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
        logMessage(otp)
    };
   
    const getOtp = async (event) => {
        logMessage("hhhh")
        event.preventDefault();
        logMessage(localStorage.getItem('forgot_email'))
        try {
            startLoading();
            if(!localStorage.getItem('forgot_email') || localStorage.getItem('forgot_email') == ""){
                logMessage("resend otp on previous screen", 'alert'); 
            }   
            logMessage(localStorage.getItem('forgot_email'))
            const data = {
                "email": localStorage.getItem('forgot_email'),
                "isLive": false
            };
            const response = await api.sendOtp(data)
            const apiResponse = new ApiResponse(
                response.status,
                response.message
            );
            if (apiResponse.status == 1) {
                logMessage(apiResponse.message, 'alert');
                stopLoading();
                navigate(paths.CHANGE_PASSWORD_SCREEN);
            } else {
                logMessage(apiResponse.message, 'alert');
            }

        } catch (error) {
            logMessage(error);
        } finally {
            stopLoading();
        }
    };
    useEffect(() => {
        inputRefs.current[0].focus();
    }, []);

    const handleBackspace = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && !otp[index]) {
            inputRefs.current[index - 1].focus();
        }
    };
    const verifyOtp = async (event) => {
        event.preventDefault();
        logMessage(otp);
        const otpNumber= otp[0] + otp[1]+ otp[2] + otp[3];
        logMessage(otpNumber);
        try {
            startLoading();
            if(!localStorage.getItem('forgot_email') || localStorage.getItem('forgot_email') == ""){
                logMessage("resend otp on previous screen", 'alert'); 
            }   
            logMessage(localStorage.getItem('forgot_email'))
            const data = {
                "email": localStorage.getItem('forgot_email'),
                "otp": parseFloat(otpNumber)
            };
            const response = await api.verifyOtp(data)
            const apiResponse = new ApiResponse(
                response.status,
                response.message
            );
            if (apiResponse.status == 1) {
                logMessage("email"+localStorage.getItem('forgot_email'))
                localStorage.setItem('forgot_email_verify', localStorage.getItem('forgot_email'));
                // localStorage.clear('forgot_email');
                logMessage(apiResponse.message, 'alert');
                stopLoading();
                navigate(paths.CHANGE_PASSWORD_SCREEN);
            } else {
                logMessage(apiResponse.message, 'alert');
            }

        } catch (error) {
            logMessage(error);
        } finally {
            stopLoading();
        }
    };
    return (
        <div className='intro_screen_container' style={{ backgroundImage: `url(${imagePaths.auth_background})` }}>
            <div className="intro_content">
                <div className="left_content">
                    <img className="intro_images" src={imagePaths.otp_side_image} alt="diamond" />
                </div>
                <div className="right_content intro_text_data forgotscreen">
                    <img src={imagePaths.dreamer_logo} ></img>
                    <div className='forgot_container'>
                        <h2>OTP Verification</h2>
                        <p>Enter the verification code we just sent on your email address.</p>
                        <div className='form-container'>
                            <form onSubmit={verifyOtp}>
                                <div className='form-item otpbox'>
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            className="otp-input"
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                            onKeyDown={(e) => handleBackspace(index, e)}
                                            autoFocus={index === 0}
                                            ref={(el) => inputRefs.current[index] = el}
                                            required
                                            />
                                    ))}
                                </div>
                                <div className='button-group intro-buttons'>
                                    <button className='colored-button click-buttons primary-button'>Verify</button>
                                </div>
                              
                            </form>
                            <div className="form-item-resend">
                                    <p style={{cursor:"pointer"}} onClick={getOtp} >Didn’t received code? <span style={{color:"#552F83", fontWeight:"700"}}>Resend</span></p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpScreen;
