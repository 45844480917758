import React from "react";
// import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {

  // const navigate = useNavigate();

  // const handleBack = () => {
  //   navigate(-1);
  // };

  return (
    <div className="empty-screen">
      <div className="inner_terms_content">
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            These terms and conditions apply to the Diamond Diaries app (hereby
            referred to as the "Application") for mobile devices created by Dreamer
            Networks Inc. (hereby referred to as the "Service Provider").
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            By downloading or using the Application, you automatically agree to the
            following terms. It is strongly advised that you thoroughly read and
            understand these terms before using the Application. Unauthorized
            copying or modification of the Application, any part of the Application,
            or our trademarks is strictly prohibited. Any attempts to extract the
            source code of the Application, translate the Application into other
            languages, or create derivative versions are not permitted. All
            trademarks, copyrights, database rights, and other intellectual property
            rights related to the Application remain the property of the Service
            Provider.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            The Service Provider is dedicated to ensuring that the Application is
            as beneficial and efficient as possible. Therefore, they reserve the
            right to modify the Application or charge for their services at any time
            and for any reason. The Service Provider assures you that any charges
            for the Application or its services will be clearly communicated to
            you.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            The Application stores and processes personal data that you provide to
            the Service Provider in order to deliver the Service. It is your
            responsibility to maintain the security of your phone and access to the
            Application. The Service Provider strongly advises against jailbreaking
            or rooting your phone, as such actions could compromise your phone's
            security features, expose it to malware, viruses, or malicious programs,
            and may result in the Application not functioning correctly or at
            all.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            Please note that the Application utilizes third-party services that
            have their own Terms and Conditions. Below are the links to the Terms
            and Conditions of the third-party service providers used by the
            Application:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <a href="https://policies.google.com/terms" style={{ color: '#1500ef' }}>
              <span style={{ fontWeight: 400, color: '#1500ef' }}>Google Play Services</span>
            </a>
          </li>
          <li style={{ fontWeight: 400 }}>
            <a href="https://firebase.google.com/terms/crashlytics" style={{ color: '#1500ef' }}>
              <span style={{ fontWeight: 400, color: '#1500ef' }}>Firebase Crashlytics</span>
            </a>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            The Service Provider does not assume responsibility for certain
            aspects. Some functions of the Application require an active internet
            connection, which can be Wi-Fi or provided by your mobile network
            provider. The Service Provider cannot be held responsible if the
            Application does not function fully due to lack of access to Wi-Fi or if
            you have exhausted your data allowance.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            If you use the Application outside of a Wi-Fi area, please note that
            your mobile network provider's terms of agreement still apply.
            Consequently, you may incur charges from your mobile provider for data
            usage during the connection to the Application or other third-party
            charges. By using the Application, you accept responsibility for any
            such charges, including roaming data charges if you use the Application
            outside of your home territory (i.e., region or country) without
            disabling data roaming. If you are not the bill payer for the device on
            which you are using the Application, you must obtain permission from the
            bill payer.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            Similarly, the Service Provider cannot always assume responsibility for
            your usage of the Application. For example, it is your responsibility to
            ensure that your device remains charged. If your device runs out of
            battery and you are unable to access the Service, the Service Provider
            cannot be held responsible.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            Regarding the Service Provider's responsibility for your use of the
            Application, while they strive to ensure that it is updated and accurate
            at all times, they rely on third parties to provide information.
            Therefore, the Service Provider accepts no liability for any loss,
            direct or indirect, resulting from relying solely on the functionality
            of the Application.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            The Service Provider may update the Application at any time. The
            availability of the Application for different operating systems (and any
            additional systems they decide to extend availability to) may change,
            requiring you to download updates to continue using the Application. The
            Service Provider does not guarantee updates to always be relevant or
            compatible with the operating system version installed on your device.
            However, you agree to accept updates to the Application when offered.
            The Service Provider may also decide to cease providing the Application
            and can terminate its use at any time without prior notice to you.
            Unless otherwise informed, upon termination, (a) your rights and
            licenses granted in these terms will end; (b) you must cease using the
            Application and delete it from your device if necessary.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            Changes to These Terms and Conditions The Service Provider may
            periodically update these Terms and Conditions. Therefore, it is
            recommended to regularly review this page for any changes. The Service
            Provider will notify you of any changes by posting the updated Terms and
            Conditions on this page.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            These terms and conditions are effective as of 2024-06-19.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400, color: '#323232' }}>
            Contact Us If you have any questions or suggestions about these Terms
            and Conditions, please do not hesitate to contact the Service Provider
            at support@dreamerdesigns.com.
          </span>
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
